import {initTrustbars} from "../../../global/js/trustbar";
import {getChildrenClassForNestedItems} from "../../../global/js/nav";
import {closeFilterBox, initFilter} from "../../../global/js/filter";
import {initActionClicks, initFormSubmitChanges, initFormSubmitClicks} from "../../../global/js/actionClick";
import {focusInputGroups} from "../../../global/js/checkInputGroups";
import {stickyHeader} from "../../../global/js/stickyheader";
import {triggerFavoritesBtns} from "../../../global/js/favorites";
import {initQuantitySpinners} from "../../../global/js/quantitySpinner";
import hoverintent from "../../../global/js/hoverintent";
import {initSlideshows} from "../../../global/js/slideshow";
import {openFullscreenGallery} from "../../../global/js/fullscreengallery";
import {stickyBox} from "../../../global/js/stickyBox.js";
import {buildMobileMenu, hideMobileMenu, showMobileMenu} from "../../../global/js/mobilemenu";
import {
    excludeModal,
    triggerConfirmModalLink,
    triggerModal,
    triggerOpenLinkInModal,
    triggerOpenTextInModal
} from "../../../global/js/modal";
import {triggerBasketBtns, triggerUpdateBasketView} from "../../../global/js/basket";
import {initToggleSlide} from "../../../global/js/toggle";
import {initItemlistSlider} from "../../../global/js/itemListSlider";
import {setSrcVideos} from "../../../global/js/video";
import {initScrollbars} from "../../../global/js/scrollbar";
import {isTouchDevice} from "../../../global/js/touchDevice";
import {
    hideAllCustomSelect,
    hideCustomSelect,
    initCustomSelects,
    updateCustomSelectRangeFilterAction
} from "../../../global/js/customSelect";
import {initJumpmarks} from "../../../global/js/jumpmark";
import {initSearchItems} from "../../../global/js/searchItems";
import {trimInputValues} from "../../../global/js/formValidator";
import {addLoadingClassAfterSubmit} from "../../../global/js/utilities/updateClasses";
import {initPasswordFields} from "../../../global/js/password";
import {lottieLoader} from "../../../global/js/lottieLoader";
import {initSlidecontents} from "../../../global/js/slidecontent";
import {initFlashmessages} from "../../../global/js/flashmessages";
import {addTableWrapper} from "../../../global/js/tableWrapper";
import {initPhoneCountryFields} from "../../../global/js/phoneCountryField";
import {initInputBody} from "../../../global/js/inputfile";
import initDcOrder from "../../../global/js/dcorder";
import {initShowMoreRatings} from "../../../global/js/rating";
import {initImageGallery} from "../../../global/js/imageGallery";
import {initInputMaskDate} from "../../../global/js/inputMaskDate";
import {initRecaptchaForms} from "../../../global/js/recaptchaForm";
import {initAjaxCollectionFilter} from "../../../global/js/ajaxCollectionFilter";
import {initDatepicker} from "../../../global/js/datepicker";
import {initContentTabs} from "../../../global/js/contentTabs";
import {initHistorySlider} from "../../../global/js/history";
import {initAjaxFormApi} from "../../../global/js/ajaxFormApi";
import {initScrollActivate} from "../../../global/js/scrollactivate";
import {initRangeslider} from "../../../global/js/rangeSlider";

window.addEventListener("load", (event) => {
    const mobileMenu = document.querySelector('.mobileMenu');
    const mobileMenuToggle = document.getElementById("js-toggleNavigation");
    const overlay = document.getElementById("overlay");
    const IsTouchDevice = isTouchDevice();
    const stickyHeaderArea = document.querySelector('.headerMain');

    let breakPointSM = 1024;
    let sendFavoriteByJS = false;
    let sendItemToBasketByJS = false;
    let updateBasketByJS = false;

    if (typeof GLOBALS!== "undefined") {
        breakPointSM = GLOBALS.gridBreakpoints.sm;
        sendFavoriteByJS = GLOBALS.sendFavoriteByJS;
        sendItemToBasketByJS = GLOBALS.sendItemToBasketByJS;
        updateBasketByJS = GLOBALS.updateBasketByJS;
    }

    if (mobileMenu && mobileMenuToggle && overlay) {
        const mobileMenuClose = mobileMenu.querySelector(".mobileMenu__close");

        buildMobileMenu(mobileMenu);

        mobileMenuToggle.addEventListener("click", function () {
            showMobileMenu(mobileMenu, overlay, mobileMenuToggle);
        })

        mobileMenuClose.addEventListener("click", function () {
            hideMobileMenu(mobileMenu, overlay, mobileMenuToggle);
        });

        overlay.addEventListener("click", function () {
            hideMobileMenu(mobileMenu, overlay, mobileMenuToggle);
            for (let filterbox of document.querySelectorAll('.filterBox')) {
                closeFilterBox(filterbox);
            }
        });
    }

    initTrustbars();

    initFilter();

    initActionClicks();

    initFormSubmitClicks();

    initFormSubmitChanges();

    trimInputValues();

    focusInputGroups();

    initInputBody();

    initPhoneCountryFields();

    stickyHeader(stickyHeaderArea);

    if (window.outerWidth < breakPointSM) {
        for (let parent of document.querySelectorAll('.navgroup > ul > li')) {
            getChildrenClassForNestedItems(parent);
        }
    }

    if (sendFavoriteByJS) {
        triggerFavoritesBtns();
    }

    if (sendItemToBasketByJS) {
        triggerBasketBtns();
    }

    if (updateBasketByJS) {
        triggerUpdateBasketView();
    }

    triggerModal();
    excludeModal();
    triggerOpenLinkInModal();
    triggerConfirmModalLink();
    triggerOpenTextInModal();

    initQuantitySpinners();

    initToggleSlide();

    if (!IsTouchDevice) {
        for (let el of document.querySelectorAll('.itembox,.iconbarItem')) {
            hoverintent(el);
        }
    }
    for (let el of document.querySelectorAll('.js-mainNavigation li')) {
        hoverintent(el);
    }

    setSrcVideos();

    initSlideshows();

    initImageGallery();

    initScrollbars();

    initSlidecontents();

    initItemlistSlider();

    initCustomSelects();

    initRangeslider(document,function (e){
        if(e.slider.closest(".js-customSelect")){
            updateCustomSelectRangeFilterAction(e.slider.closest(".js-customSelect"),e.value);
        }
    });

    // Tooltip, when necessary
    // import {Tooltip} from "../../../default/global/js/tooltip-custom";

    //var tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-toggle="tooltip"]'));
    //var tooltipList = tooltipTriggerList.map(function (tooltipTriggerEl) {
    //    return new Tooltip(tooltipTriggerEl);
    //});

    initSearchItems(document.querySelectorAll('.js-searchInput'));

    initFlashmessages();

    let offsetTopJumpmarks = 0;
    if (stickyHeaderArea) {
        offsetTopJumpmarks = stickyHeaderArea.clientHeight;
    }

    if (typeof GLOBALS!== "undefined") {
        if(GLOBALS.offsetTopJumpmarks){
            offsetTopJumpmarks += GLOBALS.offsetTopJumpmarks;
        }
    }

    initJumpmarks(offsetTopJumpmarks);

    for (let item of document.querySelectorAll(".js-openGallery")) {
        item.addEventListener('click', function (event) {
            openFullscreenGallery(item);
        });
    }

    let offsetTopStickyBox = 30;
    if (stickyHeaderArea) {
        offsetTopStickyBox += stickyHeaderArea.clientHeight;
    }

    for (let pinBox of document.querySelectorAll(".js-stickyBox")) {
        stickyBox(pinBox, {
            offsetTop: offsetTopStickyBox
        });
    }
    lottieLoader();

    addLoadingClassAfterSubmit();
    initPasswordFields();

    addTableWrapper();

    initDcOrder();

    initShowMoreRatings();

    initInputMaskDate();

    initRecaptchaForms();

    initAjaxCollectionFilter();

    initDatepicker();

    initHistorySlider();

    initContentTabs();

    initAjaxFormApi();

    initScrollActivate();

    window.main = {
        hideAllCustomSelect: function (){
            hideAllCustomSelect();
        },
        hideCustomSelect: function (select){
            hideCustomSelect(select);
        }
    }
});